<template>
  <div>
    <SubHeader>
      <b-row class="mt-2 mb-1">
        <b-col cols="8" class="mr-auto">
          <b-row>
            <b-col cols="auto">
              <b-icon-arrow-clockwise v-if="!reloading" :variant="iconVariant" @click="refresh(true)" font-scale="1.5"
                                      class="clickable computer-header-icon"/>
              <b-icon-arrow-clockwise v-else :variant="iconVariant" font-scale="1.5" animation="spin"
                                      class="computer-header-icon"/>
              <small class="ml-2">Letzte Aktualisierung: {{ lastDataFetched }}</small>
            </b-col>
            <b-col cols="4">
              <b-form-input v-model="search" debounce="500" size="sm"
                            placeholder="Suche nach Hostname oder IP-Adresse..."/>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="auto">
          <div class="mt-1">{{ pcCount }} RZPCs</div>
        </b-col>
      </b-row>
    </SubHeader>
    <div v-if="groupsFiltered">
      <div v-for="group in groupsFiltered" :key="group.id">
        <Group :group="group" class="mt-3">
          <template #group-content>
            <div v-for="computer in computers(group)" :key="computer.id">
              <RzpcRow :computer="computer" @refresh="refresh"/>
            </div>
          </template>
        </Group>
      </div>
    </div>
    <ScrollToTop/>
  </div>
</template>

<script>
import _ from "lodash";
import dayjs from "dayjs";
import {mapActions, mapGetters} from "vuex";
import Group from "@/components/lists/Group";
import SubHeader from "@/components/structure/SubHeader";
import RzpcRow from "@/components/lists/rzpcList/RzpcRow";
import ScrollToTop from "@/components/utility/ScrollToTop";

export default {
  name: "RzpcList",
  components: {
    Group,
    RzpcRow,
    SubHeader,
    ScrollToTop
  },
  data() {
    return {
      search: '',
      interval: null,
      reloading: false,
      lastDataFetched: '-'
    }
  },
  methods: {
    ...mapActions(['getSites']),
    ...mapActions(['resetPcs']),
    ...mapActions(['fetchSite']),
    ...mapActions(['getComputerList']),
    ...mapActions(['setLoadingState']),
    computers(group) {
      return _.filter(group.computers, (computer) => {
        if(computer.details.customer !== null && computer.details.customer_rpc !== null) {
          return computer.hostname.toLowerCase().includes(this.search.toLowerCase()) ||
              computer.ipv4_address.includes(this.search.toLowerCase()) ||
              computer.details.computer.serial_number.toLowerCase().includes(this.search.toLowerCase()) ||
              computer.details.customer.kdnr.toLowerCase().includes(this.search.toLowerCase()) ||
              computer.details.customer.vorname.toLowerCase().includes(this.search.toLowerCase()) ||
              computer.details.customer.zuname.toLowerCase().includes(this.search.toLowerCase()) ||
              computer.details.customer_rpc.kdnr.toLowerCase().includes(this.search.toLowerCase()) ||
              computer.details.customer_rpc.vorname.toLowerCase().includes(this.search.toLowerCase()) ||
              computer.details.customer_rpc.zuname.toLowerCase().includes(this.search.toLowerCase())
        }
        else if(computer.details.customer !== null) {
          return computer.hostname.toLowerCase().includes(this.search.toLowerCase()) ||
              computer.ipv4_address.includes(this.search.toLowerCase()) ||
              computer.details.computer.serial_number.toLowerCase().includes(this.search.toLowerCase()) ||
              computer.details.customer.kdnr.toLowerCase().includes(this.search.toLowerCase()) ||
              computer.details.customer.vorname.toLowerCase().includes(this.search.toLowerCase()) ||
              computer.details.customer.zuname.toLowerCase().includes(this.search.toLowerCase())
        }
        else if(computer.details.customer_rpc !== null) {
          return computer.hostname.toLowerCase().includes(this.search.toLowerCase()) ||
              computer.ipv4_address.includes(this.search.toLowerCase()) ||
              computer.details.computer.serial_number.toLowerCase().includes(this.search.toLowerCase()) ||
              computer.details.customer_rpc.kdnr.toLowerCase().includes(this.search.toLowerCase()) ||
              computer.details.customer_rpc.vorname.toLowerCase().includes(this.search.toLowerCase()) ||
              computer.details.customer_rpc.zuname.toLowerCase().includes(this.search.toLowerCase())
        }
        else {
          return computer.hostname.toLowerCase().includes(this.search.toLowerCase()) ||
              computer.ipv4_address.includes(this.search.toLowerCase()) ||
              computer.details.computer.serial_number.toLowerCase().includes(this.search.toLowerCase())
        }
      })
    },
    async refresh(forceReload = true) {
      this.reloading = true
      this.fetchSite('RZPC')
          .then(async(response) => {
            await this.getComputerList({ site: response, force: forceReload })
          })
      this.lastDataFetched = dayjs().format('HH:mm:ss')
      this.reloading = false
    }
  },
  computed: {
    ...mapGetters(['groups']),
    ...mapGetters(['pcCount']),
    ...mapGetters(['siteSelected']),
    ...mapGetters(['userThemeLight']),
    iconVariant() {
      if(this.userThemeLight) { return 'dark' }
      return 'light'
    },
    groupsFiltered() {
      return _.filter(this.groups, (group) => {
        return _.filter(group.computers, (computer) => {
          return computer.hostname.toLowerCase().includes(this.search.toLowerCase()) || computer.ipv4_address.includes(this.search.toLowerCase())
        }).length > 0
      })
    }
  },
  beforeMount() {
    this.resetPcs()
  },
  async mounted() {
    this.setLoadingState(true)
    await this.getSites()
    await this.refresh(false)
    this.setLoadingState(false)
    this.interval = setInterval(function () {
      this.refresh(false)
    }.bind(this), 10000)
  },
  beforeDestroy: function () {
    clearInterval(this.interval);
    this.resetPcs()
  }
}
</script>

<style lang="scss" scoped>
</style>
