<template>
  <div>
    <b-card body-class="p-0 mb-2" header-tag="header" :header-class="['card-row', {rounded: !expand}]">
      <template #header>
        <b-container fluid class="px-2 clickable">
          <b-row @click.self="getDetails">
            <b-col cols="auto" class="p-0" @click.self="getDetails">
              <ComputerOnline :computer="computer"/>
              <UserConnected :computer="computer"/>
              <WinUser :computer="computer"/>
              <CustomerAssigned :computer="computer"/>
            </b-col>
            <b-col cols="2" @click.self="getDetails">
              <b-row class="pl-2">
                <b-col cols="7" @click.self="getDetails" class="p-0">
                  <Clipboard :value="computer.hostname" title="Hostname kopieren"/>
                </b-col>
                <b-col cols="5" @click.self="getDetails" class="p-0">
                  <Clipboard :value="computer.ipv4_address" title="IPv4-Adresse kopieren"/>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="1" @click.self="getDetails">
              <b-badge v-if="computer.template" variant="primary">{{ computer.template.displayname }} - #{{ computer.template.template_id }}</b-badge>
              <span v-else>-</span>
            </b-col>
            <b-col cols="auto" class="ml-auto p-0" @click="getDetails">
              <ComputerWarnings :computer="computer"/>
            </b-col>
          </b-row>
        </b-container>
      </template>
      <TransitionExpand>
        <ComputerDetails v-if="expand" :computer="computer" :singleView="false" :rzpc="true" @refresh="refresh"/>
      </TransitionExpand>
    </b-card>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import Clipboard from "@/components/utility/Clipboard.vue";
import TransitionExpand from "@/components/utility/TransitionExpand";
import WinUser from "@/components/lists/computerList/computerRow/WinUser";
import ComputerDetails from "@/components/details/computerDetails/ComputerDetails";
import UserConnected from "@/components/lists/computerList/computerRow/UserConnected";
import ComputerOnline from "@/components/lists/computerList/computerRow/ComputerOnline";
import ComputerWarnings from "@/components/lists/computerList/computerRow/ComputerWarnings";
import CustomerAssigned from "@/components/lists/computerList/computerRow/CustomerAssigned.vue";

export default {
  name: "RzpcRow",
  props: ['computer'],
  components: {
    CustomerAssigned,
    ComputerDetails,
    Clipboard,
    UserConnected,
    WinUser,
    ComputerOnline,
    ComputerWarnings,
    TransitionExpand,
  },
  data() {
    return {
      interval: null,
      hostnameControl: false,
      expand: false,
      details: null,
    }
  },
  methods: {
    ...mapActions(['addDetails']),
    ...mapActions(['removeDetails']),
    ...mapActions(['getComputerDetails']),
    refresh() {
      this.$emit('refresh')
    },
    async getDetails() {
      if(!this.expand) {
        await this.addDetails(this.computer.pc_uuid)
        await this.getComputerDetails()
        this.expand = true
      } else {
        await this.removeDetails(this.computer.pc_uuid)
        clearInterval(this.interval)
        this.expand = false
        this.details = null
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  }
}
</script>

<style lang="scss" scoped>
</style>
